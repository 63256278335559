import React from 'react';
import { graphql } from 'gatsby';
// import { TransitionState } from 'gatsby-plugin-transition-link';
import { WorkPageProps } from '../components/pages/work/types';
import WorkBody from '../components/pages/work/WorkBody';

const Works: React.FC<WorkPageProps> = (props) => (
  // <TransitionState>
  //   {({ transitionStatus }) =>
  <WorkBody
    {...{
      // transitionStatus,
      ...props,
    }}
  />
  // }
  // </TransitionState>
);

export default Works;

export const pageQuery = graphql`
  query($id: String!) {
    works: sanityCategory(id: { eq: $id }) {
      seoTitle
      seoDescription
      seoImage {
        asset {
          fluid {
            src
          }
        }
      }
      caseStudies {
        ... on SanitySingleCaseStudy {
          _type
          caseStudy {
            mainImage {
              asset {
                fluid(maxWidth: 1712) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            mainVideo {
              asset {
                id
                url
              }
            }
            id
            darkBackground
            clientName
            title
            slug {
              current
            }
          }
        }
        ... on SanityTwoCaseStudies {
          _type
          caseStudy {
            mainImage {
              asset {
                fluid(maxWidth: 1712) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            mainVideo {
              asset {
                id
                url
              }
            }
            id
            darkBackground
            clientName
            title
            slug {
              current
            }
          }
          caseStudySecond {
            title
            id
            clientName
            darkBackground
            slug {
              current
            }
            mainImage {
              asset {
                fluid(maxWidth: 1712) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            mainVideo {
              asset {
                id
                url
              }
            }
          }
        }
      }
    }
    categories: allSanityCategory(sort: { fields: title }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`;
